<template>
  <section class="new_cssa">
    
    <!--工具条-->
    <toolbar
      :buttonList="buttonList"
      @callFunction="callFunction"
      :buttonListmsg="meetingmsg"
    ></toolbar>
    <!--列表内容-->
    <el-table
      :data="meetings"
      highlight-current-row
      @current-change="selectCurrentRow"
      :row-style="{height:'40px'}"
      :cell-style="{padding:'0px'}"
      @row-dblclick="lookJueyiList"
      :header-cell-style="{background:'#dce4f1',color:'#000000'}"
      :row-class-name="tableRowClassName"
      style="width: 100%"
    >
      <el-table-column type="index"></el-table-column>
      <el-table-column label="会议名称" prop="MeetingName" align="center" min-width="180px">
        <template scope="scope">
          <span style="cursor:default">{{scope.row.MeetingName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="会议地点" prop="MeetingName" align="center" min-width="80px">
        <template scope="scope">
          <span style="cursor:default">{{scope.row.MeetingAddress}}</span>
        </template>
      </el-table-column>
      <el-table-column label="会议日期" prop="MeetingTime" :formatter="formatMeetingTime" align="center"></el-table-column>
      <el-table-column label="会议时间" :formatter="formatTime"   align="center"></el-table-column>
      <el-table-column label="创建人" prop="CreatedBy" align="center"></el-table-column>
      <!-- <el-table-column label="参会人员" prop="PartInUserName" align="center" :show-overflow-tooltip="true"></el-table-column> -->
      <!-- <el-table-column label="审批人" prop="ApprovalName" align="center"></el-table-column> -->
      <!-- <el-table-column label="抄送人" prop="CCUserName" align="center"></el-table-column>
      <el-table-column label="备注" prop="Remark" align="center"></el-table-column> -->
      <el-table-column
        label="维护时间"
        prop="LastUpdateTime"
        align="center"
      ></el-table-column>
      <el-table-column label="操作"
                       min-width="80px">
        <template slot-scope="scope">
          <p>
            <el-tooltip content="点击查看会议相关信息" placement="left" effect="light">
              <el-link type="primary" :underline="false" @click="lookJueyiList(scope.row)" >会议信息</el-link>
            </el-tooltip>
            <el-tooltip class="item" effect="light" content="点击发布可将会议行程推送至参会成员手机app" placement="left">
              <el-tag size="mini" style="margin-left:5px" type="warning" v-if="scope.row.Published==0 && scope.row.CreatedId==myusercode" effect="plain" @click="publishMeeting(scope.row)" ><span style="cursor:default;">待发布</span></el-tag>
              <el-tag size="mini" style="margin-left:5px" type="success" v-else-if="scope.row.Published==1 && scope.row.CreatedId==myusercode" effect="plain" @click="publishMeeting(scope.row)" ><span style="cursor:default;">已发布</span></el-tag>
            </el-tooltip>
          </p>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination align='center'
                   @current-change="handleCurrentChange"
                   :current-page="currentPage"
                   :page-size="pageSize"
                   layout="prev, pager, next, jumper"
                   :page-count="total"
                   :total="totaldata">
    </el-pagination>
    <!-- <br/> -->
    <!--新增、编辑界面-->
    <el-dialog
      :visible.sync="addFormVisible"
      v-model="addFormVisible"
      :fullscreen="true"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @close="diaclose"
      center
    >
    <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">{{title}}</span>
    </template>
      <el-form
        :model="addForm"
        label-width="85px"
        :rules="addFormRules"
        ref="addForm"
        style="white-space:nowrap;"
      >
        <el-form-item label="会议名称:" prop="MeetingName">
          <el-input
            v-model="addForm.MeetingName"
            maxlength="150"
            show-word-limit
            auto-complete="off"
            placeholder="请填写会议名称（可简述会议主题）"
          ></el-input>
        </el-form-item>
        <el-form-item label="会议议程:" prop="MeetingDescription">
          <el-input v-model="addForm.MeetingDescription" type="textarea"
                    :autosize="{ minRows: 5, maxRows: 15}" maxlength="1500"
                     show-word-limit placeholder="请简述会议议程"></el-input>
        </el-form-item>
        <el-form-item label="会议地点:" prop="MeetingAddress">
          <el-input
            v-model="addForm.MeetingAddress"
            maxlength="60" show-word-limit auto-complete="off"
            placeholder="请填写会议地点（如 XXX会议室）"
          ></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="9">
            <el-form-item label="会议日期:" prop="MeetingTime">
               <el-date-picker style="width:168px"
                  v-model="addForm.MeetingTime"
                  type="date"
                  placeholder="会议日期"
                  format="yyyy年MM月dd日"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="开始时间:" prop="StartTime" :key="addForm.StartTime">
                <el-time-select
                  style="width:120px;"
                  placeholder="开始时间"
                  v-model="addForm.StartTime"
                  :picker-options="{
                    start: '08:00',
                    step: '00:5',
                    end: '18:30'
                  }">
                </el-time-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="结束时间:" prop="EndTime" :key="addForm.EndTime">
                <el-time-select
                  style="width:120px;"
                  placeholder="结束时间"
                  v-model="addForm.EndTime"
                  :picker-options="{
                    start: '08:30',
                    step: '00:5',
                    end: '19:30',
                    minTime: addForm.StartTime
                  }">
                </el-time-select>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="22">
            <el-form-item label="参会人员:" prop="PartInUserName">
              <el-input v-model="addForm.PartInUserName" placeholder="请选择参会及相关人员" type="textarea" readonly>{{partinName}}</el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button type="text" style="margin-top:11px;margin-left:3px;" size="mini" @click="handleChoosePartinUser">选择人员</el-button>
            <el-button type="text" style="margin-top:11px;margin-left:3px;" size="mini" @click="handleChoosePartinUserGroup">常用人员</el-button>
            <!--<el-button type="text">
              <userchoosetool @partincallFunction="partincallFunction" :callbackmsg="partinpeople"></userchoosetool>
            </el-button> -->
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="22">
            <el-form-item label="抄送人:" prop="CCUserName">
              <el-input v-model="addForm.CCUserName" placeholder="可选择抄送人"  readonly>{{ccusername}}</el-input>
              <el-button type="text" style="margin-left:3px;" size="mini" @click="handleChooseCCUser">选择人员</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="2">
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" >
            <el-row>
              <el-col :span="20">
                <el-form-item label="主持人:" prop="ApprovalName">
                  <el-tooltip content="若审核开启，会议决议项任务由主持人进行审核" effect="light" placement="top">
                    <el-input v-model="addForm.ApprovalName" placeholder="选择会议主持人" readonly></el-input>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <el-button type="text">
                  <userchoosetool @assigncallFunction="assigncallFunction" :callbackmsg="assignpeo"></userchoosetool>
                </el-button>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="16">
            <el-row>
              <el-col :span="4">
                <el-form-item label="新建后审核:" prop="BeforeApproval">
                  <el-tooltip :content="addForm.BeforeApproval?'决议项任务新建后经过主持人审核后方可执行':'决议项任务新建后无需审核即刻执行'" effect="light" placement="top">
                    <el-switch
                      style="margin-left:10px;margin-bottom:2px"
                      v-model="addForm.BeforeApproval"
                      active-color="#13ce66">
                    </el-switch>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="完成后审核:" prop="AfterApproval">
                  <el-tooltip :content="addForm.AfterApproval?'任务完成后经过主持人审核后方可闭环':'决议项任务完成即闭环无需审核'" effect="light" placement="top">
                    <el-switch
                      style="margin-left:10px;margin-bottom:2px"
                      v-model="addForm.AfterApproval"
                      active-color="#13ce66">
                    </el-switch>
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="22">
            <el-form-item label="会议创建人:" prop="PublishName">
              <el-input v-model="addForm.PublishName" placeholder="请选择发布人" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <!-- <el-button type="text">
              <userchoosetool @usercallFunction="usercallFunction" :callbackmsg="doublepeople"></userchoosetool>
            </el-button> -->
          </el-col>
        </el-row>
        <el-form-item label="备注:" prop="Remark">
          <el-input v-model="addForm.Remark" :autosize="{ minRows: 3, maxRows: 9}"  type="textarea" maxlength="500" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="addSubmit">提交</el-button>
        <el-button type="default"
                   @click="addFormVisible=false">关闭</el-button>
      </div>
    </el-dialog>
    <!--修改审批人界面-->
    <el-dialog
      :visible.sync="editFormVisible"
      v-model="editFormVisible"
      :close-on-click-modal="false"
      style="width: 60%; left: 20%"
      center
    >
    <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">修改审批人</span>
    </template>
      <el-form :model="editForm" label-width="85px">
        <el-row>
          <el-col :span="0.1" style="margin-top:10px">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="20">
            <el-form-item label="审批人:" prop="ApprovalName">
              <el-input v-model="editForm.ApprovalName" placeholder="请选择审批人" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button type="text">
              <userchoosetool @assigncallFunction="assigncallFunction" :callbackmsg="assignpeo"></userchoosetool>
            </el-button>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="goon" @click="editSubmit">提交</el-button>
      </div>
    </el-dialog>
    <!--修改抄送人界面-->
    <el-dialog
      :visible.sync="editCCFormVisible"
      v-model="editCCFormVisible"
      :close-on-click-modal="false"
      style="width: 60%; left: 20%"
      center
    >
    <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">修改抄送人</span>
    </template>
      <el-form :model="editCCForm" label-width="85px">
        <el-row>
          <el-col :span="0.1" style="margin-top:10px">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="20">
            <el-form-item label="抄送人:" prop="CCUserName">
              <el-input v-model="editCCForm.CCUserName" placeholder="请选择抄送人" readonly>{{ccusername2}}</el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
              <el-button type="text" size="mini" @click="handleChooseCCUser2">选择人员</el-button>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="goon" @click="editCCSubmit">提交</el-button>
      </div>
    </el-dialog>

    <!--决议项界面-->
    <el-dialog :visible.sync="jueyiFormVisible"
               v-model="jueyiFormVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               @close="clickclose"
               :fullscreen="true"
               center>
               <!-- style="width:110%;margin-left:-4%;" -->
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">{{meetingTitle}}</span>
      </template>
      <el-tabs v-model="tabactiveName" @tab-click="handleTabClick" style="margin-top:-25px">
        <el-tab-pane label="会议信息" name="tab1">
          <el-form :model="jueyiaddForm"
                  label-width="85px"
                  ref="addForm"
                  style="white-space:nowrap;">
            <el-row>
              <el-form-item label="会议名称:" prop="MeetingName">
                <el-input v-model="jueyiaddForm.MeetingName"
                          auto-complete="off" :readonly="true" placeholder="会议名称" ></el-input>
              </el-form-item>
              <el-form-item label="会议议程:"
                            prop="MeetingName">
                <el-input v-model="jueyiaddForm.MeetingDescription"
                          auto-complete="off" :readonly="true" type="textarea"
                    :autosize="{ minRows: 5, maxRows: 15}" 
                     placeholder="会议名称" ></el-input>
              </el-form-item>
              <el-form-item label="会议地点:" prop="MeetingAddress">
                <el-input
                  v-model="jueyiaddForm.MeetingAddress" :readonly="true" auto-complete="off"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-col :span="9">
                <el-form-item label="会议日期:" prop="MeetingTime">
                  <el-date-picker style="width:168px" v-model="jueyiaddForm.MeetingTime" type="date"
                      placeholder="会议日期" format="yyyy年MM月dd日" readonly value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="开始时间:" prop="StartTime" :key="jueyiaddForm.StartTime">
                    <el-time-select style="width:120px;" placeholder="开始时间" v-model="jueyiaddForm.StartTime"
                      :picker-options="{ start: '08:00', step: '00:5', end: '18:30' }" readonly>
                    </el-time-select>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="结束时间:" prop="EndTime" :key="jueyiaddForm.EndTime">
                    <el-time-select style="width:120px;" placeholder="结束时间" v-model="jueyiaddForm.EndTime"
                      :picker-options="{ start: '08:30', step: '00:5', end: '19:30', minTime: jueyiaddForm.StartTime }" readonly>
                    </el-time-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="22">
                <el-form-item label="参会人员:" prop="PartInUserName">
                  <el-input v-model="jueyiaddForm.PartInUserName" placeholder="请选择参会和相关人员" type="textarea" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="22">
                <el-form-item label="抄送人:" prop="CCUserName">
                  <el-input v-model="jueyiaddForm.CCUserName" placeholder="抄送人"  readonly>{{ccusername}}</el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2">
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-row>
                  <el-col :span="20">
                    <el-form-item label="主持人:" prop="ApprovalName">
                      <el-tooltip content="若审核开启，会议决议项任务由主持人进行审核" effect="light" placement="top">
                        <el-input v-model="jueyiaddForm.ApprovalName"  readonly></el-input>
                      </el-tooltip>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <el-col :span="4">
                    <el-form-item label="新建后审核:" prop="BeforeApproval">
                      <el-tooltip :content="jueyiaddForm.BeforeApproval?'决议项任务新建后经过主持人审核后方可执行':'决议项任务新建后无需审核即刻执行'" effect="light" placement="top">
                        <el-switch :disabled="true"
                          style="margin-left:10px;margin-bottom:2px"
                          v-model="jueyiaddForm.BeforeApproval"
                          active-color="#13ce66">
                        </el-switch>
                      </el-tooltip>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="完成后审核:" prop="AfterApproval">
                      <el-tooltip :content="jueyiaddForm.AfterApproval?'任务完成后经过主持人审核后方可闭环':'决议项任务完成即闭环无需审核'" effect="light" placement="top">
                        <el-switch :disabled="true"
                          style="margin-left:10px;margin-bottom:2px"
                          v-model="jueyiaddForm.AfterApproval"
                          active-color="#13ce66">
                        </el-switch>
                      </el-tooltip>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-form-item label="会议创建人:" prop="PublishName">
              <el-input v-model="jueyiaddForm.PublishName" placeholder="请选择发布人" readonly></el-input>
            </el-form-item>
            <el-form-item label="备注:" prop="Remark">
              <el-input v-model="jueyiaddForm.Remark" type="textarea"
                    :autosize="{ minRows: 3, maxRows: 9}"  readonly maxlength="50" show-word-limit></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="会议讨论" name="tab2">
          <el-table
            :data="meetingsqs"
            highlight-current-row
            :row-style="{height:'40px'}"
            :cell-style="{padding:'0px'}"
            @row-dblclick="checkQsInfo"
            :header-cell-style="{background:'#dce4f1',color:'#000000'}"
            :row-class-name="tableRowClassName"
            style="width: 100%"
          >
            <el-table-column type="index"></el-table-column>
            <!-- <el-table-column label="会议名称" prop="MeetingName" align="center">
              <template scope="scope">
                <span style="cursor:default">{{scope.row.MeetingName}}</span>
              </template>
            </el-table-column> -->
            <el-table-column label="问题分类" prop="QuestionType"  align="center">
              <template scope="scope">
                <el-tag type="warning">{{formatQuestionType(scope.row.QuestionType)}}</el-tag>
                <!-- <span style="cursor:default">{{formatQuestionType(scope.row.QuestionType)}}</span> -->
              </template>
            </el-table-column>
            <el-table-column label="问题名称" prop="QuestionName" align="center">
              <template scope="scope">
                <span style="cursor:default">{{scope.row.QuestionName}}</span>
              </template>
            </el-table-column>
            <el-table-column label="问题描述" prop="QuestionDescription" align="center"></el-table-column>
            <!-- <el-table-column label="会议日期" prop="MeetingTime" :formatter="formatMeetingTime" align="center"></el-table-column> -->
            <!-- <el-table-column label="参会人员" prop="PartInUserName" align="center"></el-table-column> -->
            <!-- <el-table-column label="审批人" prop="ApprovalName" align="center"></el-table-column> -->
            <!-- <el-table-column label="抄送人" prop="CCUserName" align="center"></el-table-column> -->
            <!-- <el-table-column label="备注" prop="Remark" align="center"></el-table-column> -->
            <el-table-column label="发布人" prop="CreatedBy" align="center"></el-table-column>
            <el-table-column
              label="发布时间"
              prop="CreatedOn"
              align="center"
            ></el-table-column>
            <el-table-column label="操作"
                            min-width="80px">
              <template slot-scope="scope">
                <!-- <p><el-link type="primary" :underline="false" @click="checkInfo(scope.row)" v-if="scope.row.Status != 5">查看</el-link></p> -->
                <p>
                  <el-tooltip content="点击查看或参与会议问题讨论" placement="right" effect="light">
                    <el-link type="primary" :underline="false" @click="checkQsInfo(scope.row)" >参与讨论</el-link>
                    <!-- <el-link type="primary" :underline="false" @click="yearplanchoose(scope.row)">{{scope.row.TagName==null?'关联':scope.row.TagName}}</el-link> -->
                  </el-tooltip>
                </p>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="会议汇报" name="tab3">
          <el-select v-model="huibaoFilter" size="mini" :filterable="true"
           placeholder="从参会人员中选择汇报人进行筛选" :clearable="true" style="width:240px;" >
            <el-option
              v-for="item in joinMeetingUsers"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-button style="margin-left:5px;margin-bottom:3px;" icon="el-icon-search" size="mini" @click="handleQueryHuibao" circle></el-button>
          <el-table :data="meetingtasklist"
                    highlight-current-row
                    row-key="Id"
                    lazy
                    :indent="30"
                    :load="load"
                    :tree-props="{children: 'children',hasChildren: 'IsHasChildren'}"
                    :fit="true"
                    :cell-style="mycellStyle"
                    :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                    @row-dblclick="checkInfo"
                    @cell-mouse-enter="tabmouseEnter"
                    @cell-mouse-leave="tabmouseLeave"
                    style="width: 100%;">
            <!-- <el-table-column type="index"></el-table-column> -->
            <el-table-column type="index" width="10">
              <template scope="scope">
              </template>
            </el-table-column>
            <el-table-column label="工作项目"
                            prop="WorkItem"
                            min-width="380px">
              <template slot-scope="scope">

                      
                <!-- <i class="el-icon-timer"
                  v-if="scope.row.IsHasChildren == true"></i>
                <i class="el-icon-timer"
                  v-if="localuser != scope.row.PersonOfDuty && scope.row.IsHasChildren == false"></i>
                    -->
                    
                  <span :style="{'cursor':'default','display':'block','padding-left':'20px','margin-top':scope.row.IsHasChildren?'-22px':'0px','margin-bottom':scope.row.IsHasChildren ?'0px':'15px','margin-left': scope.row.MarginLeft+'px' }">{{scope.row.Number}}、{{scope.row.WorkItem}}
                    <!-- <el-button @click="handleCollect(scope.row)" style="color:#FF9800;margin-right:3px;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null" :title="scope.row.CollectId==null?'特别关注':'已特别关注'" type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button> -->
                  </span>
                    <!-- <span :style="{'cursor':'default'}">{{scope.row.WorkItem}}</span> -->
                      <!-- <span v-if="!scope.row.WorkDescription" ><el-button @click="handleCollect(scope.row)" style="color:#FF9800;margin-right:3px;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null" :title="scope.row.CollectId==null?'特别关注':'已特别关注'" type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button>{{scope.row.WorkItem}}</span>
                      <el-button v-else style="color:#606266;font-weight:normal;cursor:default" type="text"><el-button @click="handleCollect(scope.row)" style="margin-right:3px;color:#FF9800;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null"  :title="scope.row.CollectId==null?'特别关注':'已特别关注'"  type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button>{{scope.row.WorkItem}}</el-button> -->
                      <!-- <el-tooltip v-else placement="right-start" effect="light" :enterable="true">
                        <div slot="content" v-html="'<span style=\'color:#999\' >工作项目:</span><br/>'+scope.row.WorkItem+ '<br/><span style=\'color:#999\' >工作描述:</span><br/>'+scope.row.WorkDescription.replace(/\n/g,'<br/>')+ '<br/><span style=\'color:#999\' >创建于:</span><br/>'+scope.row.CreateTime+ '<br/><span style=\'color:#999\' >计划完成时间:</span><br/>'+scope.row.PlanComplateTime+ '<br/><span style=\'color:#999\' >进度:</span><br/>'+formatProgress(scope.row)+ '<br/><span style=\'color:#999\' >最新进度:</span><br/>'+(scope.row.LastProgressDescription==null?'':scope.row.LastProgressDescription.replace(/\n/g,'<br/>'))" ></div>
                        <el-button style="color:black;cursor:default" type="text"><el-button @click="handleCollect(scope.row)" style="margin-right:3px;color:#FF9800;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null"  :title="scope.row.CollectId==null?'特别关注':'已特别关注'"  type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button>{{scope.row.WorkItem}}</el-button>
                      </el-tooltip> -->
              </template>
            </el-table-column>
            <!-- <el-table-column label="相关会议" prop="MeetingName" min-width="140px"></el-table-column> -->
            <el-table-column label="汇报人" prop="MtCreatedBy" min-width="70px"></el-table-column>
            <el-table-column label="添加时间" prop="CreatedOn" min-width="80px">
              <template slot-scope="scope">
                <el-tooltip placement="left">
                  <div slot="content">
                    {{ formatTipCreatedOn2( scope.row) }}
                  </div>
                  <div>
                    {{ formatCreatedOn2( scope.row) }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <!-- <el-table-column label="具体描述及要求" prop="WorkDescription"></el-table-column> -->
            <!-- <el-table-column label="计划完成时间" prop="PlanComplateTime"
                            min-width="115px" :formatter="formatPlanComplateTime">
              <template slot-scope="scope">
                <div v-if="new Date()>new Date(new Date(new Date(scope.row.PlanComplateTime).toLocaleDateString()).getTime()+24*60*60*1000-1)&&scope.row.TaskProgressValue<100">
                  <font color=red>{{formatPlanComplateTime(scope.row)}}</font>
                  &nbsp;<el-tag v-if="scope.row.IsProlongTime" type="success" size="mini">延</el-tag>
                </div>
                <div v-else>
                  <font color=black>{{formatPlanComplateTime(scope.row)}}</font>
                  &nbsp;<el-tag v-if="scope.row.IsProlongTime" type="success" size="mini">延</el-tag>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column label="任务责任人"
                            prop="PersonOfDuty"
                            min-width="90px"></el-table-column>
            <el-table-column label="任务状态"
                            prop="Status"
                            align="center"
                            min-width="100px">
              <template slot-scope="scope">
                  <div>
                    <div v-if="scope.row.TaskProgressValue<100"  style="font-size:10px;">进度:{{formatProgress(scope.row)}}</div>
                    <el-tag v-if="scope.row.Status == 0"
                            effect="dark"> 进行中</el-tag>
                    <el-tag v-if="scope.row.Status == 2"
                            effect="dark"
                            type="success">已完成</el-tag>
                    <el-tag v-if="scope.row.Status == -1"
                            effect="dark"
                            type="info">已取消</el-tag>
                    <el-tag v-if="scope.row.Status == 3"
                            effect="dark"
                            type="danger">退回</el-tag>
                    <el-tag v-if="scope.row.Status == 1"
                            effect="dark"
                            type="warning">已完成审核中</el-tag>
                    <el-tag v-if="scope.row.Status == 4"
                            effect="dark"
                            type="warning">新建任务待审核</el-tag>
                    <el-tag v-if="scope.row.Status == 5"
                            effect="dark"
                            type="danger">取消任务待审核</el-tag>
                    <el-tag v-if="scope.row.Status == 6"
                            effect="dark"
                            type="danger">新建任务退回</el-tag>
                  </div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="进度"
                            prop="TaskProgressValue"
                            :formatter="formatProgress"
                            align="left"
                            min-width="55px"></el-table-column> -->
            <el-table-column label="任务类型"
                            prop="CommonTaskClassifyText"
                            min-width="100px"
                            :formatter="formatCommonTaskClassifyText"></el-table-column>
            <el-table-column label="任务来源"
                            prop="Source"
                            :formatter="formatSource"
                            min-width="90px">
              <template slot-scope="scope">
                <div style="font-size:10px">{{formatSource(scope.row)}}</div>
                <div v-if="scope.row.AssignName" style="font-size:10px">由{{scope.row.AssignName}}指派</div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="创建时间"
                            prop="CreateTime"
                            min-width="80px"
                            :formatter="formatCreateTime">
              <template slot-scope="scope">
                <el-tooltip placement="left">
                  <div slot="content">
                    {{ formatTipCreateTime( scope.row) }}
                  </div>
                  <div>
                    {{ formatCreateTime( scope.row) }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="完成后审核"
                            prop="IsNeedApproval"
                            min-width="70px"
                            align="center">
              <template slot-scope="scope">
                <el-tag :type="scope.row.IsNeedApproval ? 'warning' : 'success'"
                        disable-transitions>
                  {{scope.row.IsNeedApproval ? "是" : "否"}}
                </el-tag>
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="备注"  width="100"  prop="Remark"></el-table-column> -->
            <el-table-column label="操作"
                            min-width="80px">
              <template slot-scope="scope">
                  <p><el-link type="primary" :underline="false" @click="checkInfo(scope.row)" v-if="scope.row.Status != 5">查看</el-link></p>
                  <!-- <el-tooltip class="item" effect="light" :content="scope.row.MeetingName" placement="left">
                    <el-tag size="mini" type="success" v-if="scope.row.IsJueyi==1" effect="plain" @click="setNottoJueyi(scope.row)" ><span style="cursor:default;">已转决议项</span></el-tag>
                  </el-tooltip>
                  <p><el-link type="primary" :underline="false" @click="settoJueyi(scope.row)" v-if="scope.row.IsJueyi==0 && currentRow.CreatedId==myusercode">转决议项</el-link></p> -->
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页器 -->
          <el-pagination align='center' 
                        v-if="mtpagerShow"
                        @current-change="handleMeetingTaskCurrentChange"
                        :current-page="mtcurrentPage"
                        :page-size="mtpageSize"
                        layout="prev, pager, next, jumper"
                        :page-count="mttotal"
                        :total="mttotaldata">
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="会议决议项" name="tab4">
          <el-form :inline="true">
            <el-button-group>
              <el-tooltip content="新增决议项任务" effect="light" placement="top">
              <el-button type="primary" size="mini" 
                      v-show="this.currentRow && this.currentRow.CreatedId==myusercode" 
                      icon="el-icon-plus" style="margin-bottom:2px"
                      @click="insertmeeting">新增任务</el-button>
              </el-tooltip>
              <el-button type="primary" size="mini" 
                        v-show="this.currentRow && this.currentRow.CreatedId==myusercode" 
                        icon="el-icon-minus" style="margin-bottom:2px"
                        @click="cancelTask">取消任务</el-button>
            </el-button-group>
            <el-form-item label="任务状态:"
                          style="margin-left: 10%;">
              <el-select v-model="taskStatus" size="mini"
                        style="width: 60%;">
                <el-option v-for="item in StatusModules"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="任务进度(≥):"
                          style="margin-left: -80px;">
              <el-input v-model="progressValue" size="mini"
                        placeholder="请输入"
                        type="number"
                        min=0
                        max=100></el-input>
            </el-form-item>
            <el-form-item style="margin-left: -0px;">
              <el-button size="mini"
                        type="goon"
                        round
                        icon="el-icon-search"
                        @click="queryJueyiTaskByMeetingId">查询</el-button>
                
            </el-form-item>
            <el-button @click="handleExport()" size="mini" style="float:right">导出Excel文件到本地 </el-button>
          </el-form>
          <el-table :data="meetingList"
                    id="mytable"
                    row-key="Id"
                    lazy
                    :indent="30"
                    :load="load2"
                    :tree-props="{children: 'children',hasChildren: 'IsHasChildren'}"
                    highlight-current-row
                    :row-class-name="MultitableRowClassName"
                    :row-style="{height:'60px'}"
                    :cell-style="{padding:'0px'}"
                    @current-change="selectCurrentTaskRow"
                    @row-dblclick="checkInfo"
                    empty-text="该会议下无决议项任务"
                    :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                    :fit="true">
            <el-table-column type="index"
                            min-width="7px"></el-table-column>
            <el-table-column label="工作项目"
                            prop="WorkItem"
                            min-width="300px"
                            show-overflow-tooltip></el-table-column>
            <!-- <el-table-column label="工作描述"
                            prop="WorkDescription"
                            min-width="150px"
                            show-overflow-tooltip></el-table-column> -->
            <!-- <el-table-column label="计划完成时间"
                            prop="PlanComplateTime"
                            min-width="130px"></el-table-column> -->
            <el-table-column label="任务状态"
                              prop="Status"
                              align="center"
                              min-width="100px">
              <template slot-scope="scope">
                  <div>
                    <div v-if="scope.row.TaskProgressValue<100"  style="font-size:10px;">进度:{{formatProgress(scope.row)}}</div>
                    <el-tag v-if="scope.row.Status == 0"
                            effect="dark"> 进行中</el-tag>
                    <el-tag v-if="scope.row.Status == 2"
                            effect="dark"
                            type="success">已完成</el-tag>
                    <el-tag v-if="scope.row.Status == -1"
                            effect="dark"
                            type="info">已取消</el-tag>
                    <el-tag v-if="scope.row.Status == 3"
                            effect="dark"
                            type="danger">退回</el-tag>
                    <el-tag v-if="scope.row.Status == 1"
                            effect="dark"
                            type="warning">已完成审核中</el-tag>
                    <el-tag v-if="scope.row.Status == 4"
                            effect="dark"
                            type="warning">新建任务待审核</el-tag>
                    <el-tag v-if="scope.row.Status == 5"
                            effect="dark"
                            type="danger">取消任务待审核</el-tag>
                    <el-tag v-if="scope.row.Status == 6"
                            effect="dark"
                            type="danger">新建任务退回</el-tag>
                  </div>
              </template>
            </el-table-column>
            <el-table-column label="计划完成时间"
                            prop="PlanComplateTime"
                            min-width="115px"
                            :formatter="formatPlanComplateTime">
              <template slot-scope="scope">
                <div v-if="new Date()>new Date(new Date(new Date(scope.row.PlanComplateTime).toLocaleDateString()).getTime()+24*60*60*1000-1)&&scope.row.TaskProgressValue<100">
                  <font color=red>{{formatPlanComplateTime(scope.row)}}</font>
                  &nbsp;<el-tag v-if="scope.row.IsProlongTime" type="success" size="mini">延</el-tag>
                </div>
                <div v-else>
                  <font color=black>{{formatPlanComplateTime(scope.row)}}</font>
                  &nbsp;<el-tag v-if="scope.row.IsProlongTime" type="success" size="mini">延</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="责任人"
                            prop="PersonOfDuty"
                            min-width="100px"></el-table-column>
            <el-table-column label="最新维护内容"
                            prop="LastProgressDescription"
                            min-width="150px"
                            ></el-table-column>
            <!-- <el-table-column label="决议项来源"
                              prop="IsJueyi"
                              align="center"
                              min-width="80px">
              <template slot-scope="scope">
                  <div>
                    <el-tag v-if="scope.row.IsJueyi == 1" effect="plain" type="success">会议汇报</el-tag>
                    <el-tag v-else-if="scope.row.IsJueyi == 2" effect="plain">手动创建</el-tag>
                    <el-tag v-else-if="scope.row.IsJueyi == 0" effect="plain">其他</el-tag>
                  </div>
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="备注"
                            prop="Remark"
                            min-width="90px"
                            show-overflow-tooltip></el-table-column> -->
            <!-- <el-table-column label="附件"
                            prop="FileName"
                            min-width="90px"
                            show-overflow-tooltip></el-table-column> -->
            <el-table-column label="操作" min-width="60px">
              <template slot-scope="scope">

                <el-button size="mini" type="text" @click="checkInfo(scope.row)">查看</el-button>
                <!-- <el-button size="mini" type="text" @click="editmeeting(scope.$index,scope.row)">编辑</el-button>
                <el-button size="mini"
                          type="text"
                          @click="deletemeeting(scope.row)">删除</el-button> -->
              </template>
            </el-table-column>
            <!-- <el-table-column label="年度计划"
                            prop="Tag"
                            min-width="100px"
                            show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{scope.row.TagName}}</span>
              </template>
            </el-table-column> -->
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="会议纪要" name="tab5">          
           <el-form :model="noteForm"
                  label-width="85px"
                  ref="noteForm"
                  :rules="addNoteFormRules"
                  style="white-space:nowrap;">
              <el-form-item label="会议纪要:" prop="ContentText" >
                <div ref="editorElem" style="text-align:left"></div>
              </el-form-item>
              <el-form-item label="参考链接:" >
                <el-input v-show="noteForm.Id==''" v-model="noteForm.Link" placeholder="可填写商务运营平台通知公告链接" ></el-input>
                <el-link @click="handleOpenLink" icon="el-icon-link" v-show="noteForm.Id !='' && noteForm.Link!=''" type="primary">{{noteForm.Link}}</el-link>
              </el-form-item>
              <el-form-item label="保存状态">
                <el-tag type="info" v-if="  currentRow!=null &&  currentRow.CreatedId!=myusercode" >非会议创建人无需编辑</el-tag>
                <el-button v-if=" currentRow!=null && currentRow.CreatedId==myusercode" type="primary" :disabled="noteForm.Id!=''" @click="handleSubmitNote">{{noteForm.Id!=''?'已提交':'提交'}}</el-button>
              </el-form-item>
           </el-form>


          <!-- <div>
          </div>   -->
        </el-tab-pane>
      </el-tabs>

      <div slot="footer"
           class="dialog-footer">
        <!-- <el-button type="goon"
                   @click="addSubmit">提交</el-button> -->
        <el-button type="goon"
                   @click="jueyiFormVisible=false">关闭</el-button>
      </div>
    </el-dialog>
    <!--会议决议项任务多行添加-->
    <el-dialog :visible.sync="addMultiFormVisible"
               v-model="addMultiFormVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               style="width:90%;margin-left:5%;"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">新增会议决议项任务</span>
      </template>
      <el-form :model="addMultiForm"
               label-width="85px"
               :rules="addMultiFormRules"
               ref="addMultiForm"
               style="white-space:nowrap;">
        <el-form-item label="工作项目:"
                      prop="WorkItem">
          <el-input v-model="addMultiForm.WorkItem"
                    maxlength="50"
                    show-word-limit
                    auto-complete="off"
                    placeholder="请填写项目名称"></el-input>
        </el-form-item>
        <el-form-item label="工作描述:"
                      prop="WorkDescription">
          <el-input v-model="addMultiForm.WorkDescription"
                    type="textarea"
                    placeholder="请填写工作描述"
                    maxlength="500"
                    show-word-limit></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="计划完成时间:"
                          prop="PlanComplateTime">
              <el-date-picker v-model="addMultiForm.PlanComplateTime"
                              :picker-options="pickerOptions0"
                              type="date"
                              value-format="yyyy-MM-dd"
                              placeholder="请选择日期"
                              style="left: 20px;"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-row>
              <!-- <el-col :span="0.1"
                      style="margin-top:10px; margin-left: -1%;">
                <span style="color: red;">*</span>
              </el-col> -->
              <el-col :span="20">
                <el-form-item label="责任人:"
                              prop="PersonOfDutyCode">
                  <!-- <el-input v-model="addMultiForm.PersonOfDuty"
                            placeholder="请选择责任人"
                            readonly>{{dutyusername}}</el-input>
                  <el-button type="text"
                             size="mini"
                             @click="handleChooseDutyUser">选择人员</el-button> -->

                  <el-select v-model="addMultiForm.PersonOfDutyCode" multiple :filterable="true" placeholder="从参会人员中选择" :clearable="true"  @change="handleChangeJoinPerson" >
                    <el-option
                      v-for="item in joinMeetingUsers"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>                             
                </el-form-item>
              </el-col>
              <!-- <el-col :span="2">
                                    <el-button type="text">
                                        <userchoosetool @dutycallFunction="dutycallFunction" :callbackmsg = "dutypeo"></userchoosetool>
                                    </el-button>
                                </el-col> -->
            </el-row>
          </el-col>
        </el-row>
        <el-form-item label="量化指标：" v-show="visiableZhibiao"
                      prop="TemplateId">
          <el-input :placeholder="choosetip" style="cursor:pointer;width:85%;" :readonly="true" v-model="addMultiForm.TemplateName" class="input-with-select" @click.native="handleChooseZhibiao">
            <el-select v-model="zhibiaosource" slot="prepend" placeholder="请选择" @change="handleChangeZhibiaoSource" style="width:140px;">
              <el-option label="从指标库选择" :value="1"></el-option>
              <el-option label="临时新建" :value="2"></el-option>
            </el-select>
          </el-input>
          <el-popover
            placement="bottom"
            width="600"
            trigger="click">
            <el-table :data="lookdetail">
              <el-table-column property="FieldName" min-width="100px" label="指标项"></el-table-column>
              <el-table-column property="FieldUnit" align="center" label="值单位"></el-table-column>
              <el-table-column property="FieldType" align="center" label="值类型">
                <template scope="scope">
                  <span>{{formatType(scope.row.FieldType)}}</span>
                </template>
              </el-table-column>
              <el-table-column property="FieldLength" align="center" label="值长度"></el-table-column>
              <el-table-column property="FieldMustInput" align="center" label="是否必填">
                <template scope="scope">
                  <span>{{scope.row.FieldMustInput?'是':'否'}}</span>
                </template>
              </el-table-column>
            </el-table>
            <el-button style="margin-left:5px;" slot="reference" @click="handleLookTemplateDetail(addMultiForm.TemplateId)" type="text">预览</el-button>
          </el-popover>
        </el-form-item>
        <el-form-item label="备注:"
                      prop="Remark">
          <el-input v-model="addMultiForm.Remark"
                    type="textarea"
                    maxlength="100"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="附件:"
                      prop="FileAddress">
          <upload-files :files="fileList"
                        :key="key"
                        action="https://api.gradgroup.cn/ftp/ftp/upload"
                        :limit="5"
                        @fun="dealFiles"
                        @delfun="deleteFiles"
                        :multiple="false"
                        :IsDisabled="operation1"
                        :IsDel="operation1"></upload-files>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <!-- <el-button type="goon"
                   @click.native="addMultiSubmit">完成</el-button> -->
        <el-button type="primary"
                   @click.native="addMultiSubmit">提交</el-button>
        <el-button type="goon"
                   @click="addMultiFormVisible=false">关闭</el-button>
      </div>
    </el-dialog>

    <taskinfo v-if="checkVisible" :data="checkForm" @closeFunction="checkclose" type="normal" ></taskinfo>

    <meetingqs v-if="qscheckVisible" :data="qscheckForm" @closeFunction="qscheckclose" ></meetingqs>

        <el-dialog
        title="选择人员"
        :visible.sync="addUserVisible"
        v-model="addUserVisible"
        width="75%"
        center
        append-to-body
        >
        <UserChoose
            :data="choosedusers"
            :all="true"
            :single="false"
            @callback="chooseUserCallBack"
        ></UserChoose>
        </el-dialog>


        <el-dialog
        title="选择人员"
        :visible.sync="addUserVisible2"
        v-model="addUserVisible2"
        width="75%"
        center
        append-to-body
        >
        <UserChoose
            :data="choosedusers"
            :all="true"
            :single="false"
            @callback="chooseUserCallBack2"
        ></UserChoose>
        </el-dialog>

        <el-dialog
        title="选择人员"
        :visible.sync="addPartinUserVisible"
        v-model="addPartinUserVisible"
        width="75%"
        center
        append-to-body
        >
        <UserChoose
            :data="choosedPartinusers"
            :all="true"
            :single="false"
            @callback="chooseUserCallBackPartin"
        ></UserChoose>
        </el-dialog>

        <el-dialog
        title="选择常用人员"
        :visible.sync="addUserGroupVisible"
        v-model="addUserGroupVisible"
        center
        append-to-body
        >
        <span>从历史参会人员中选择：</span>
          <el-select v-model="groupvalue" :clearable="true" style="width:70%;" placeholder="从历史会议中选择人员" @change="handleChangeMeetingPartin">
            <el-tooltip v-for="item in groupoptions" :key="item.value" :offset="200" :enterable="false"
            :content="item.tooltip" placement="right" effect="light">
              <el-option
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-tooltip>
          </el-select>
          <el-tooltip v-show="tags.length!=0" content="点击叉号可临时移除指定人员" effect="light" placement="top" ><i style="margin-left:2px;" class="el-icon-magic-stick"></i></el-tooltip>
          <div style="margin-top:10px;margin-bottom:10px;">
            <el-tag style="margin: 2px;" effect="dark"
              v-for="tag in tags"
              :key="tag.name" disable-transitions
              :closable="true"
              @close="handleCloseTag(tag)"
              :type="tag.type">
              {{tag.name}}
            </el-tag>
          </div>
          <el-empty v-show="tags.length==0" description="点击下拉框从历史参会人员中选择"></el-empty>
          <br/>
          <br/>
          <el-button type="primary" style="margin-left:40%;" @click.native="handleSelectChangyong">选择</el-button>
          <el-button type="default" @click.native="addUserGroupVisible=false">关闭</el-button>
        </el-dialog>

    <zhibiaoconfig v-if="zhibiaopeizhi" @closeFunction="checkzhibiaoclose"  ></zhibiaoconfig>

    <zhibiaoconfigadd v-if="zhibiaopeizhiadd" @closeFunction="checkzhibiaoaddclose" :type="0" ></zhibiaoconfigadd>

  </section>
</template>
<script>
import util from "../../../util/date";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import xlsxstyle from "xlsx-style";
import {
  QueryMeetingUphold,
  GetUserPostList,
  AddTask,
  AddTaskForMeeting,
  AddMeeting,
  AddMeetingNote,
  UpdateMeeting,
  QueryPageMeetingQuestionListByUsercode,
  QueryPageMeetingTaskListByUsercode,
  QueryPageMeetingListByCreate,
  QueryPageMeetingListByAboutUsercode,
  QueryPageTasksByMeetingId,
  QueryMeetingHistoryUserlist,
  QueryMeetingNoteByMeetingId,
  DeleteMeetingById,
  UpdateMeetingUphold,
  UpdateMeetingUpholdCCUser,
  SetTaskToJueyiById,
  PublishMeetingById,
  QueryChildTasksByParentId,
  QueryYearPlanAboutTaskByYearplanId,
  QueryYearPlanChildTasksByParentId,
  QueryPageTemplateByUserCode,
  QueryTemplateDetailByTemplateId,
  CancelTask,
  CheckTaskWorkItem
} from "../../api/oa";
import {Loading} from 'element-ui'
import { getButtonList } from "../../promissionRouter";
import Userchoosetool from "../../components/Userchoosetool";
import taskinfo from "../../components/taskinfo.vue"
import meetingqs from '../../components/meetingqs.vue'
import Toolbar from "../../components/Toolbar";
import UploadFiles from "../../components/UploadFiles";
import UserChoose from "../../components/UserChoose";
import zhibiaoconfig from "../../components/zhibiaoconfig.vue"
import zhibiaoconfigadd from "../../components/zhibiaoconfigadd.vue"
import E from 'wangeditor'
export default {
  components: { taskinfo, zhibiaoconfig, zhibiaoconfigadd, meetingqs, Toolbar, Userchoosetool, UploadFiles, UserChoose },
  data() {
    return {
      editor: null,
      editorContent: '',
      loading: '',
      currentPage: 1, // 当前页码
      mtcurrentPage: 1, // 当前页码
      total: null, // 总条数
      mttotal: null, // 总条数
      totaldata: null,
      mttotaldata: null,
      pageSize: 20, // 每页的数据条数
      mtpageSize: 10, // 每页的数据条数
      page: 1,
      mtpage: 1,
      mtpagerShow: true,
      meetings: [],
      buttonList: [],
      filters: {
        name: ""
      },
      ccusername:'',
      ccusername2:'',
      partinName:'',
      userid: [], //暂存选中角色下所有用户的id
      choosedusers: [],//抄送人
      choosedPartinusers: [],//参会人
      checkForm:null,
      checkVisible:false,
      qscheckForm:null,
      taskStatus: -1,
      progressValue: 0,
      StatusModules: [
        {
          value: -1,
          label: "全部"
        },
        {
          value: 0,
          label: "进行中"
        },
        {
          value: 2,
          label: "已完成"
        },
        {
          value: 1,
          label: "已完成审核中"
        },
      ],
      qscheckVisible:false,
      addUserVisible:false,
      addUserVisible2:false,
      addPartinUserVisible:false,
      addUserGroupVisible:false,
      addFormVisible: false,
      jueyiFormVisible: false,
      addMultiForm:false,
      editFormVisible: false,
      editCCFormVisible: false,
      meetingTitle:'',
      huibaoFilter:'',
      huibaoname:'',
      title: "新增会议信息",
      departmsg: "departmsg",
      tabactiveName: 'tab1',
      catchdepart: null,
      currentRow: null,
      currentTaskRow: null,
      Meetingstatus: "每天",
      meetingmsg: "meetinglist",
      assignpeo: "assignpeo",
      canhuirenyuan: "canhuirenyuan",
      doublepeople: [],
      partinpeople: ['canhuirenyuan'],
      jueyiaddForm: {
        MeetingName:null,
        jueyiaddForm:null
      },
      addForm: {
        Id: null,
        MeetingName: null,
        MeetingDescription: null,
        MeetingAddress:null,
        MeetingTime: null,
        StartTime:'',
        EndTime:'',
        ApprovalCode: null,
        ApprovalName: null,
        BeforeApproval: true,
        AfterApproval: false,
        PublishName: null,
        PublishCode: null,
        CCUserName: null,
        CCUserCode: null,
        PartInUserName: null,
        PartInUserCode: null,
        CreatedId: null,
        CreatedBy: null,
        Remark: null
      },
      addFormRules: {
        MeetingName: [
          { required: true, message: "请填写会议名称", trigger: "blur" }
        ],
        MeetingDescription: [
          { required: true, message: "请填写会议议程", trigger: "blur" }
        ],
        MeetingAddress: [
          { required: true, message: "请填写会议地点", trigger: "blur" }
        ],
        MeetingTime: [
          { required: true, message: "请选择会议日期", trigger: "blur" }
        ],
        StartTime: [
          { required: true, message: "请选择会议开始时间", trigger: "blur" }
        ],
        EndTime: [
          { required: true, message: "请选择会议结束时间", trigger: "blur" }
        ],
        ApprovalName: [
          { required: true, message: "请选择审批人", trigger: "blur" }
        ],
        // PublishName:[
        //   { required: true, message: "请选择会议发布人", trigger: "blur" }
        // ],
        PartInUserName:[
          { required: true, message: "请选择参会人员", trigger: "blur" }
        ],
      },
      addMultiFormRules: {
        WorkItem: [
          { required: true, message: '请填写工作项目名称', trigger: 'blur' }
        ],
        WorkDescription: [
          { required: true, message: '请填写具体工作描述', trigger: 'blur' }
        ],
        PlanComplateTime: [
          { required: true, message: '请选择计划完成时间', trigger: 'blur' }
        ],
        PersonOfDutyCode: [
          { required: true, message: '请选择任务责任人', trigger: 'blur' }
        ]
      },
      addNoteFormRules: {
        ContentText: [
          { required: true, message: '请填写会议纪要内容', trigger: 'blur' }
        ],
      },
      editForm: {
        Id: null,
        ApprovalName: null,
        ApprovalCode: null
      },
      editCCForm: {
        Id: null,
        CCUserName: null,
        CCUserCode: null
      },
      joinMeetingUsers:[],
      meetingList:[],
      meetingsqs:[],
      meetingtasklist:[],
      addMultiFormVisible:false,
      pickerOptions0: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      myusercode:'',
      dutyusername: '',
      maps: new Map(),
      maps2: new Map(),
      mapLevel:new Map(),
      fileList:[],
      operation1: true,
      key: 0,
      noteForm:{
        Id:'',
        ContentText:'',
        Link:''
      },
      addMultiForm: {
        WorkItem: null,
        WorkDescription: null,
        CommonTaskClassify:32,
        TaskClassify :1,
        PlanComplateTime: null,
        PersonOfDuty: null,
        YearPlanTask: null,
        Tag: null,
        TagName: null,
        Remark: null,
        FileName: null,
        FileAddress: null,
        NeedScore:false,
        IsNeedApproval:false,
        DefaultComplated:false,
        TaskExtendData:[],
      },
      groupdata:[],
      groupvalue:null,
      groupoptions:[
        {
          value:'1',
          label:'1'
        },
        {
          value:'2',
          label:'2'
        }
      ],
      zhibiaosource:1,
      choosetip:'点击从指标库中选择',
      zhibiaoOptions: [],
      visiableZhibiao:true,
      zhibiaopeizhi:false,
      zhibiaopeizhiadd:false,
      lookdetail:[],
      typeList: [
        { label: '数字', value: 1 },
        { label: '文本', value: 2 },
        // { label: '日期', value: 3 },
        // { label: '时间', value: 4 }
      ],
      tags: [
        { name: '标签一', type: '' },
        { name: '标签二', type: 'success' },
        { name: '标签三', type: 'info' },
        { name: '标签四', type: 'warning' },
      ]
    };
  },
  methods: {
    handleExport(){
      let self = this;
      self.exportExcel();
    },
    //定义导出Excel表格事件
    exportExcel() {
        /* 从表生成工作簿对象 */
        var wb = XLSX.utils.table_to_book(document.querySelector("#mytable"));
        this.setExlStyle(wb['Sheets']['Sheet1']);
        let wb_out = xlsxstyle.write(wb,{type:'buffer'});
        /* 获取二进制字符串作为输出 */
        var wbout = XLSX.write(wb, {
            bookType: "xlsx",
            bookSST: true,
            type: "array"
        });
        var filename= util.formatDate.format(new Date(),'yyyy-MM-dd hh:mm');
        try {
            FileSaver.saveAs(
              //Blob 对象表示一个不可变、原始数据的类文件对象。
              //Blob 表示的不一定是JavaScript原生格式的数据。
              //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
              //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
              new Blob([wb_out], { type: "application/octet-stream" }),
              //设置导出文件名称
              filename+"导出会议决议项任务.xlsx"
            );
        } catch (e) {
            if (typeof console !== "undefined") console.log(e, wbout);
        }
        return wbout;
    },
    setExlStyle(data) {
      let borderAll = {  //单元格外侧框线
        top: {
          style: 'thin',
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        }
      };
      data['!cols'] = [];
      for (let key in data) {
        // console.log(key)
        if (data[key] instanceof Object) {
          var row = key.replace(/[^0-9]/ig, '')
          data[key].s = {
            border: borderAll,
            alignment: {
              horizontal: 'center',   //水平居中对齐
              vertical:'center',
              wrapText: 1
            },
            fill: row==1?{fgColor: { rgb: '4FADC2' }}:{fgColor: { indexed: 64 }},
            font:{
              name:'微软雅黑',
              sz:11,
            },
            bold:true,
            numFmt: 0
          }
          data['!cols'].push({wpx: 115});
        }
      }
      return data;
    },
    handleChooseZhibiao()
    {
      // alert();
      if(this.zhibiaosource==1)
      {
        this.handleZhibiao();
      }else if(this.zhibiaosource==2){
        this.handleAddZhibiaoConfig();
      }
    },
    handleChangeZhibiaoSource()
    {
      if(this.zhibiaosource==1)
      {
        this.choosetip='点击从指标库中选择';
      }else if(this.zhibiaosource==2){
        this.choosetip='点击新建指标';
      }
      this.addMultiForm.TemplateId=null;
      this.addMultiForm.TemplateName='';
    },
    handleAddZhibiaoConfig()
    {
      this.zhibiaopeizhiadd = true;
    },
    formatType (value) {
      for(var a=0;a<this.typeList.length;a++)
      {
        if(this.typeList[a].value==value)
        {
          return this.typeList[a].label;
        }
      }
      return ''
    },
    handleLookTemplateDetail(templateId)
    {
      let para = { templateId: templateId };
      QueryTemplateDetailByTemplateId(para).then((res) => {
        // console.log(res)
        this.lookdetail = res.data.response;
      });
    },
    handleZhibiao()
    {
      this.zhibiaopeizhi = true;
    },
    handleAddZhibiaoConfig()
    {
      this.zhibiaopeizhiadd = true;
    },
    async checkzhibiaoaddclose (data) {
      this.zhibiaopeizhiadd = false;
      if(data.added==0)
      {
        return;
      }
      await this.loadZhibiaoOptions();
      // console.log(this.zhibiaoOptions)
      this.addMultiForm.TemplateId=this.zhibiaoOptions[0].value;
      this.addMultiForm.TemplateName=this.zhibiaoOptions[0].label;
      this.$forceUpdate();
    },
    async loadZhibiaoOptions(){
      this.zhibiaoOptions=[];
      var user = JSON.parse(window.localStorage.user);
      await QueryPageTemplateByUserCode({userCode:user.sub,ispublic:false,istemp:true}).then((res) => {
        // console.log(res);
        if(res.status==200)
        {
          var data = res.data.response.data;
          data.forEach(element => {
            var option={
              value:element.Id,
              label:element.TemplateName
            }
            this.zhibiaoOptions.push(option);
          });
        }
      });
    },
    checkzhibiaoclose (row) {
      // console.log(row)
      if(row)
      {
        this.addMultiForm.TemplateId = row.Id;
        this.addMultiForm.TemplateName = row.TemplateName;
      }
      this.zhibiaopeizhi = false;
    },
    handleQueryHuibao()
    {
      this.huibaoname='';
      for(var a =0;a<this.joinMeetingUsers.length;a++)
      {
        if(this.joinMeetingUsers[a].value==this.huibaoFilter)
        {
          this.huibaoname=this.joinMeetingUsers[a].label;
          break;
        }
      }
      this.mtcurrentPage = 1;
      this.mtpage = 1;
      this.mtpagerShow=false;
      this.$nextTick(()=>{
        this.mtpagerShow = true;
      });
      this.getMeetingTasks();
    },
    publishMeeting(row)
    {
      let self = this;
      this.$confirm("确定要发布会议行程吗？(若已发布则再次推送消息)", "提示", {}).then(() => {
        PublishMeetingById({meetingId:row.Id}).then((res) => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          // this.currentRow.MeetingId=row.MtMeetingId;
          setTimeout(() => {
            this.users=[];
            this.getMeetings();
          }, 300);
        })
      }).catch(()=>{});
    },
    settoJueyi(row)
    {
      this.$confirm("确定要将此任务转到决议项列表中吗？", "提示", {}).then(() => {
        var para={};
        para.Id=row.MtId;
        para.jueyi=1;
        SetTaskToJueyiById(para).then((res) => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          // this.currentRow.MeetingId=row.MtMeetingId;
          setTimeout(() => {
            this.users=[];
            this.getMeetingTasks();
          }, 300);
        })
      }).catch(()=>{});
    },
    setNottoJueyi(row)
    {
      let self = this;
      if(self.currentRow.CreatedId!=self.myusercode)
      {
          this.$message({
            message: '当前用户非会议创建人，无需操作决议项',
            type: 'warning'
          });
          return;
      }
      this.$confirm("确定要将此任务在决议项列表中移除吗？", "提示", {}).then(() => {
        var para={};
        // para.meetingId=row.MtMeetingId;
        para.Id=row.MtId;
        para.jueyi=0;
        SetTaskToJueyiById(para).then((res) => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          // this.currentRow.MeetingId=row.MtMeetingId;
          setTimeout(() => {
            this.users=[];
            this.getMeetingTasks();
          }, 300);
        })
      }).catch(()=>{});
    },
    getMeetingTasks () {
      // var user = JSON.parse(window.localStorage.user);
      let para = {
        // userCode: user.sub,
        meetingId: this.currentRow.Id,
        pageIndex: this.mtpage,
        pageSize: this.mtpageSize,
        queryKey: this.huibaoname
      };
      QueryPageMeetingTaskListByUsercode(para).then((res) => {
        this.mttotal = res.data.response.pageCount;
        this.mttotaldata = res.data.response.dataCount;
        var arr=new Array();
        var number = (this.mtpage-1)*this.mtpageSize;
        res.data.response.data.forEach(element => {
          // console.log(element);
          element.Number=++number;
          element.MarginLeft=3;
          element.Level = 1;
          this.mapLevel.set(element.Id,1);
          arr.push(element)
        });
        this.meetingtasklist = arr;
        // this.users = res.data.response.data;
      });
    },
    formatSource: function (row, column) {
      return row.ParentId != null ? '被指派' : row.ParentId == null ? '新建' : '未知';
    },
    formatCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.dateformat(new Date(row.CreateTime));
    },
    formatTipCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.format(new Date(row.CreateTime), 'yyyy-MM-dd hh:mm');
    },
    formatTipCreatedOn2: function (row, column) {
      return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.format(new Date(row.CreatedOn), 'yyyy-MM-dd hh:mm');
    },
    formatCreatedOn2: function (row, colume) {
      return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.dateformat(new Date(row.CreatedOn));
    },
    formatCommonTaskClassifyText: function (row, colume) {
      if (row.TaskClassify == 1) {
        return row.CommonTaskClassifyText;
      } else {
        if (row.TaskClassify == 2) {
          return "跨部门协调";
        }
        else if (row.TaskClassify == 3) {
          return "例会项目";
        } else if (row.TaskClassify == 4) {
          return "年度计划";
        } else if (row.TaskClassify == 5) {
          return "专项会议";
        }
        else {
          return "其他";
        }
      }
    },
    rowformat(element)
    {
      var tag = element.TagType<=1?element.Tag:'';
      var has = this.mapLevel.has(tag)
      // var has = this.mapLevel.has(element.Tag)
      var parentLevel = 0;
      if(has)
      {
        parentLevel = this.mapLevel.get(tag);
      }
      else
      {
        parentLevel = this.mapLevel.get(element.ParentId);
      }
      var level = parentLevel+1;
      if(level==2)
      {
        element.MarginLeft = 30;
      }else if(level == 3){
        element.MarginLeft = 60;
      }else if(level == 4){
        element.MarginLeft = 90;
      }else if(level == 5){
        element.MarginLeft = 120;
      }else if(level == 6){
        element.MarginLeft = 150;
      }else if(level == 7){
        element.MarginLeft = 180;
      }else if(level == 8){
        element.MarginLeft = 210;
      }else if(level == 9){
        element.MarginLeft = 240;
      }else if(level == 10){
        element.MarginLeft = 270;
      }
      element.Level = level;
      this.mapLevel.set(element.Id,level);
      return element;
    },
    load (tree, treeNode, resolve) {
      // 将当前选中节点数据存储到map中
      this.maps.set(tree.Id, { tree, treeNode, resolve })
      

      var mytaskid = tree.TaskId?tree.TaskId:tree.Id;
      let para = {
        taskId: mytaskid
      };
      if(tree.IsHasChildrenYearplan && tree.IsHasNormalTasks)
      {
        console.log(1)
        QueryYearPlanChildTasksByParentId(para).then((res) => {
          // console.log(res.data.response);
          var myarr = new Array();
          var number = 0;
          res.data.response.forEach(element => {
            // console.log(element)
            // console.log(element.IsHasNormalTasks+","+element.IsHasChildrenYearplan);
            number++;
            element = this.rowformat(element);
            element.Number = number;
            if(element.IsHasNormalTasks || element.IsHasChildrenYearplan)
            {
              element.IsHasChildren = true;
            }else{
              element.IsHasChildren = false;
            }
            myarr.push(element)
          });
          resolve(myarr);
        });
      }else if(tree.IsHasChildrenYearplan)
      {
        console.log(2);
        QueryYearPlanChildTasksByParentId(para).then((res) => {
          // console.log(res.data.response);
          var myarr = new Array();
          var number = 0;
          res.data.response.forEach(element => {
            element.Number = ++number;
            element = this.rowformat(element);
            // console.log(element);
            // console.log(element.IsHasNormalTasks+","+element.IsHasChildrenYearplan);
            if(element.IsHasNormalTasks || element.IsHasChildrenYearplan)
            {
              element.IsHasChildren = true;
            }else{
              element.IsHasChildren = false;
            }
            myarr.push(element)
          });
          resolve(myarr);
        });
        // QueryYearPlanChildTasksByParentId(para).then((res) => {
        //   resolve(res.data.response)
        // });
      }else if(tree.IsHasNormalTasks)
      {

        console.log(3)
        let para2={
          sort: 1,
          // yearplantaskid: tree.Id,
          yearplantaskid: mytaskid,
          taskclassify:0,
          month:'',
          month2:'',
          pageIndex: 1,
          pageSize: 500
        };
        QueryYearPlanAboutTaskByYearplanId(para2).then((res) => {
          // console.log(res.data.response.data);
          var myarr = new Array();
          var number = 0;
          res.data.response.data.forEach(element => {
            element.Number = ++number;
            element = this.rowformat(element);
            myarr.push(element)
          });
          resolve(myarr);
          // resolve(res.data.response.data)
        });
      }else if(tree.IsHasCommonTasks || tree.IsHasChildren){
        console.log(4);
        let para4 = {
          taskId: mytaskid,
        };
        QueryChildTasksByParentId(para4).then((res) => {
          // resolve(res.data.response)
          var myarr = new Array();
          var number = 0;
          res.data.response.forEach(element => {
            element.Number = ++number;
            element = this.rowformat(element);
            myarr.push(element)
          });
          resolve(myarr);
        });
      }
    },
    load2 (tree, treeNode, resolve) {
      // 将当前选中节点数据存储到map中
      this.maps2.set(tree.Id, { tree, treeNode, resolve })
      
      var mytaskid = tree.TaskId?tree.TaskId:tree.Id;
      let para = {
        taskId: mytaskid
      };
      if(tree.IsHasCommonTasks || tree.IsHasChildren){
        console.log(4);
        let para4 = {
          taskId: mytaskid,
        };
        QueryChildTasksByParentId(para4).then((res) => {
          // resolve(res.data.response)
          var myarr = new Array();
          var number = 0;
          res.data.response.forEach(element => {
            element.Number = ++number;
            // element = this.rowformat(element);
            myarr.push(element)
          });
          resolve(myarr);
        });
      }

    },
    mycellStyle(row,column,rowIndex,columnIndex)
    {
      // return 'background:red'
      // console.log(row);
      if(row.row.Level===1)
      {
        return 'background:#fff'
      }
      else if(row.row.Level===2)
      {
        return 'background:#e9f3ff'
      }
      else if(row.row.Level===3)
      {
        return 'background:#d4e9ff';
      }
      else if(row.row.Level===4)
      {
        return 'background:#bfdeff';
      }
      else if(row.row.Level===5)
      {
        return 'background:#aad2ff';
      }
      else if(row.row.Level===6)
      {
        return 'background:#96c8ff';
      }
      else if(row.row.Level===7)
      {
        return 'background:#81bdff';
      }
      else if(row.row.Level===8)
      {
        return 'background:#6eb3ff';
      }
      else if(row.row.Level===9)
      {
        return 'background:#5ba8ff';
      }
      else if(row.row.Level===10)
      {
        return 'background:#499eff';
      }
      return ''
    },
    tabmouseEnter(row, column, cell, event)
    {
      if(column.property!='WorkItem')return;
      this.collectBtn=true;
      this.collectRowId=row.Id;
      // console.log(row)
    },
    tabmouseLeave(row, column, cell, event)
    {
      this.collectBtn=false;
      this.collectRowId='';
    },
    handleTabClick(tab, event) 
    {
      if(tab.name == 'tab2')
      {
        this.queryMeetingQuestions();
      }else if(tab.name == 'tab3')
      {
        this.getMeetingTasks();
      }else if(tab.name == 'tab4')
      {
        this.queryJueyiTaskByMeetingId();
      }
      else if(tab.name == 'tab5')
      {
        // console.log(this.$refs.editorElem);
        if(this.editor==null)
        {
          this.editor = new E(this.$refs.editorElem)
          this.editor.config.height=600;
          this.editor.config.onchange = (html) => {
            this.noteForm.ContentText = html;
            // console.log(this.noteForm.ContentText)
          }
          this.editor.create()     // 创建富文本实例
        }
        this.queryMeetingNote(this.currentRow.Id);
      }
        // console.log(tab, event);
    },
    queryMeetingQuestions() {
      var user = JSON.parse(window.localStorage.user);
      QueryPageMeetingQuestionListByUsercode({
        // userCode: user.sub,
        meetingId: this.currentRow.Id,
        searchKey: this.filters.name,
        pageIndex: this.page,
        pageSize: this.pageSize,
      }).then(res => {
        this.meetingsqs = res.data.response.data;
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
      });
    },
    formatQuestionType: function(QuestionType) {
      // console.log(QuestionType)
      if(QuestionType==1)
      {
        return '售前咨询';
      }
      else if(QuestionType == 2)
      {
        return '售中支持';
      }
      else if(QuestionType == 3)
      {
        return '售后服务';
      }
      else if(QuestionType == 4)
      {
        return '资质问题';
      }
      else if(QuestionType == 5)
      {
        return '产销对接';
      }
      else if(QuestionType == 6)
      {
        return '人事管理';
      }
      else if(QuestionType == 7)
      {
        return '财务管理';
      }
      else if(QuestionType == 8)
      {
        return '信息化服务';
      }else{
        return '';
      }
    },
    formatProgress: function (row, col) {
      return row.TaskProgressValue + "%";
    },
    formatPlanComplateTime: function (row, colume) {
      return (!row.PlanComplateTime || row.PlanComplateTime == '') ? '' : util.formatDate.format(new Date(row.PlanComplateTime), 'yyyy-MM-dd');
    },
    Loadingstart () {
      this.loading = Loading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0,0,0,0.1)'
      })
    },
    Loadignend () {
      this.loading.close();
    },
    handleChangeJoinPerson(val)
    {
      for(var a=0;a<this.joinMeetingUsers.length;a++)
      {
        if(this.joinMeetingUsers[a].value==val)
        {
          this.addMultiForm.PersonOfDuty = this.joinMeetingUsers[a].label;
          break;
        }
      }
    },
    handleOpenLink()
    {
      window.open(this.noteForm.Link,'_blank');
    },
    handleSubmitNote(){
      console.log(this.noteForm)
      this.$refs.noteForm.validate(valid => {
        console.log(valid)
        if (valid) {
          this.$confirm("确定要提交会议纪要吗？", "提示", {}).then(() => {
            this.Loadingstart();
            var para ={};
            para.MeetingId = this.currentRow.Id;
            para.ContentText = this.noteForm.ContentText;
            para.Link = this.noteForm.Link;
            AddMeetingNote(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: res.data.msg,
                  type: 'success'
                });
                this.Loadignend();
                this.queryMeetingNote(this.currentRow.Id);
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                });
                this.Loadignend();
              }
            }).catch(er=>{
                this.Loadignend();
            });
          })
        }
      })
    },
    queryMeetingNote(meetingid)
    {
      let _self = this;
      // console.log(_self.editor)
      this.noteForm.ContentText='';
      _self.editor.txt.html('');
      _self.editor.txt.editor.enable();
      this.noteForm.Link='';
      this.noteForm.Id='';
      QueryMeetingNoteByMeetingId({
        meetingId: meetingid,
      }).then(res => {
        // console.log(res);
        if(res.status==200)
        {
          var data = res.data.response;
          if(data)
          {
            this.noteForm.ContentText=data.ContentText;
            _self.editor.txt.html(data.ContentText);
            _self.editor.txt.editor.disable();
            this.noteForm.Link = data.Link;
            this.noteForm.Id = data.Id;
          }
        }
        // var arr = new Array();
        // res.data.response.data.forEach(element => {
        //   if(element.IsHasCommonTasks)
        //   {
        //     element.IsHasChildren=true;
        //   }else{
        //     element.IsHasChildren=false;
        //   }
        //   arr.push(element);
        // });
        // this.meetingList = arr;
      });
    },
    addMultiSubmit () {
      let self = this;
      this.$refs.addMultiForm.validate(valid => {
        if (valid) {
          let para = Object.assign({}, this.addMultiForm);
          para.MeetingId=this.currentRow.Id;
          var user = JSON.parse(window.localStorage.user);
          para.CreatedId = user.sub;
          para.CreatedBy = user.name;
          this.Loadingstart();
          var uidstr = '';
          var unamestr = '';
          for(var a=0;a<para.PersonOfDutyCode.length;a++)
          {
            uidstr += para.PersonOfDutyCode[a];
            uidstr += ',';
            var uname = this.joinMeetingUsers.find(u=>u.value==para.PersonOfDutyCode[a])
            unamestr += uname.label;
            unamestr += ',';
          }

          var checkParam = { userCodes:uidstr,workitems:[para.WorkItem]}
          CheckTaskWorkItem(checkParam).then((res)=>{
            // console.log(res);
            var r = res.data.response;
            if(r.length>0){
              var item = r[0];
              var t = item.CreateTime;
              var w = item.WorkItem;
              var p = item.PersonOfDuty;
              var content = "您即将新创建的工作任务「"+w+"」曾在"+p+"任务列表中于"+t+"创建过，若继续提交则重复，请确认是否同一任务事项，避免重复建立，还要继续提交吗？";
              this.$confirm(content, '提示', {confirmButtonText: '继续提交',cancelButtonText: '暂不提交'}).then(() => {
                self.doSubmit(para,uidstr,unamestr);
              }).catch(() => {
                this.Loadignend();
              });
            }else{
                self.doSubmit(para,uidstr,unamestr);
            }
          });
        }
      })
    },
    doSubmit(para,uidstr,unamestr){
      // console.log(para);
      // console.log(uidstr);
      // console.log(unamestr);
      para.PersonOfDutyCode=uidstr;
      para.PersonOfDuty=unamestr;
      AddTaskForMeeting(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          this.Loadignend();
          this.addMultiFormVisible = false;
          this.queryJueyiTaskByMeetingId();
          // this.$refs['addForm'].resetFields();
          // this.addFormVisible = false;
          // this.addAssignFormVisible = false;
          // // window.location.reload();
          // this.needName = null;
          // this.dutyneedname = null;
          // this.getNewTask();
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
          this.Loadignend();
        }
      }).catch(er=>{
          this.Loadignend();
      });
      // if (this.multitype == "新增") {
      //   this.meetingList.push(para);
      // } else {
      //   this.$set(this.meetingList, this.multiindex, para);
      // }
      // this.$refs.addMultiForm.resetFields();
      // this.addMultiFormVisible = false;
      // this.multitype = null;
      // this.multiindex = null;
      // this.addMultiForm.Tag = null;
      // this.addMultiForm.TagName = null;
      // this.key += 1;
    },
    //多行提交
    addMultiSubmit2 () {
      this.$refs.addMultiForm.validate(valid => {
        if (valid) {
          let para = Object.assign({}, this.addMultiForm);
          if (this.multitype == "新增") {
            this.meetingList.push(para);
          } else {
            this.$set(this.meetingList, this.multiindex, para);
          }
          this.$refs.addMultiForm.resetFields();
          this.addMultiFormVisible = false;
          this.multitype = null;
          this.multiindex = null;
          this.addMultiForm.Tag = null;
          this.addMultiForm.TagName = null;
          this.key += 1;
        }
      })
    },
    dealFiles (data) {
      // console.log(data.backData);
      if (data.backData.length > 0) {
        this.addMultiForm.FileArry = data.backData;
        var filename = "";
        var fileaddress = "";
        data.backData.forEach(element => {
          filename += element.FileName;
          filename += ",";
          fileaddress += element.URL;
          fileaddress += ",";
        });
        this.addMultiForm.FileName = filename;
        this.addMultiForm.FileAddress = fileaddress;
      }
    },
    deleteFiles (data) {
      if (data.backData.length >= 0) {
        this.addMultiForm.FileArry = "";
        var filename = "";
        var fileaddress = "";
        data.backData.forEach(element => {
          filename += element.FileName;
          filename += ",";
          fileaddress += element.URL;
          fileaddress += ",";
        });
        this.addMultiForm.FileName = filename;
        this.addMultiForm.FileAddress = fileaddress;
      }
    },
    handleChooseDutyUser () {
      this.addDutyUserVisible = true;
    },
    MultitableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      }
      return '';
    },
    //多行新增
    insertmeeting () {
      let self = this;
      let row = self.currentRow;
      if (row.Published!=1) {
        this.$message({
          message: "当前操作的会议暂未发布，无需操作决议项任务",
          type: "warning"
        });
        return;
      }
      this.multitype = "新增";
      this.addMultiFormVisible = true;
      this.addMultiForm.WorkItem=null;
      this.addMultiForm.WorkDescription=null;
      this.addMultiForm.PlanComplateTime=null;
      this.addMultiForm.Remark=null;
      // var user = JSON.parse(window.localStorage.user);
      // this.addMultiForm.PersonOfDuty = user.name;
      // this.addMultiForm.PersonOfDutyCode = user.sub;
      // let para = { userCode: user.sub };
      // GetYearPlanListByUserCode(para).then((res) => {
      //   this.YearPlanTaskList = res.data.response;
      // });
    },
    cancelTask()
    {
      let self = this;
      if (self.currentRow.Published!=1) {
        this.$message({
          message: "当前操作的会议暂未发布，无需操作决议项任务",
          type: "warning"
        });
        return;
      }
      let row = self.currentTaskRow;
      if (!row) {
        this.$message({
          message: "请选择要取消的任务数据！",
          type: "warning"
        });
        return;
      }
      this.$confirm("确定要将选中决议项任务取消吗？", "提示", {}).then(() => {
        var user = JSON.parse(window.localStorage.user);
        let para = {
          taskId: self.currentTaskRow.Id,
          wantCancelUserCode: user.sub,
          wantCancelUserName: user.name
        };
        CancelTask(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            setTimeout(() => {
              self.queryJueyiTaskByMeetingId();
            }, 300);
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }).catch(()=>{});
    },
    checkclose () {
      this.checkVisible = false;
      this.pValue1 = 0;
    },
    qscheckclose()
    {
      this.qscheckVisible = false;
    },
    clickclose () {
      this.$refs['addForm'].resetFields();
    },
    lookJueyiList(row)
    {
      // console.log(row);
      this.jueyiFormVisible = true;
      this.tabactiveName="tab1";
      this.meetingTitle = '「'+row.MeetingName+'」';
      Object.assign(this.jueyiaddForm,row)
      // console.log(this.jueyiaddForm)
      // this.jueyiaddForm.MeetingName = row.MeetingName;
      // this.jueyiaddForm.MeetingDescription = row.MeetingDescription;
      // this.queryJueyiTaskByMeetingId(row.Id);
      this.joinMeetingUsers=[];
      // console.log(row.PartInUserName)
      // console.log(row.PartInUserCode)
      var usernames = row.PartInUserName;
      var usercodes = row.PartInUserCode;
      var usernameArr = usernames.split(',');
      var usercodeArr = usercodes.split(',');
      // console.log(usernameArr)
      // console.log(usercodeArr)
      for(var a =0;a<usernameArr.length;a++)
      {
        if(usercodeArr[a]=='')
        {
          continue;
        }
        var name = usernameArr[a];
        var code = usercodeArr[a];
        var obj={
          value:code,
          label:name
        }
        this.joinMeetingUsers.push(obj);
      }
      // console.log(this.joinMeetingUsers)
    },
    checkInfo(row)
    {
      // console.log(row);
      this.checkForm = row;
      this.checkVisible = true;
    },
    checkQsInfo(row)
    {
      // console.log(row);
      var data = Object.assign({},row);
      data.QuestionId = data.Id;
      this.qscheckForm = data;
      this.qscheckVisible = true;
    },
    handleCloseTag(tag)
    {
      this.tags.splice(this.tags.indexOf(tag), 1);
    },
    handleSelectChangyong()
    {
      if(this.tags.length==0)
      {
        this.$message({
          message: "所选人员数量不能为零",
          type: "warning"
        });
        return;
      }
      var usercodeStr = '';
      var usernameStr = '';
      this.tags.forEach(element => {
        usercodeStr+=element.val;
        usercodeStr+=',';
        usernameStr+=element.name;
        usernameStr+=',';
      });
      usercodeStr = usercodeStr.substr(0, usercodeStr.length - 1);
      usernameStr = usernameStr.substr(0, usernameStr.length - 1);
      // console.log(usercodeStr)
      // console.log(usernameStr)
      this.addForm.PartInUserCode=usercodeStr;
      this.addForm.PartInUserName=usernameStr;
      this.addUserGroupVisible = false;
    },
    handleChangeMeetingPartin(val)
    {
      // console.log(val);
      var obj = {};
      this.tags=[];
      for(var a =0;a<this.groupdata.length;a++)
      {
        if(this.groupdata[a].PartInUserCode==val)
        {
          obj = this.groupdata[a];
          break;
        }
      }
      var partincodeArr = obj.PartInUserCode.split(',');
      var partinnameArr = obj.PartInUserName.split(',');
      for(var b = 0;b<partincodeArr.length;b++)
      {
        if(partincodeArr[b])
        {
          this.tags.push({
            val:partincodeArr[b],
            name:partinnameArr[b],
            type:'danger'
          })
        }
      }
    },
    handleChoosePartinUserGroup()
    {
      let self=this;
      self.groupoptions=[];
      self.tags=[];
      var user = JSON.parse(window.localStorage.user);
      QueryMeetingHistoryUserlist({
        userCode: user.sub,
        type:1
      }).then(res => {
        var arr = res.data.response;
        self.groupdata = arr;
        arr.forEach(element => {
          self.groupoptions.push({
            value:element.PartInUserCode,
            label:element.MeetingName,
            tooltip:element.PartInUserName
          })
        });
        // console.log(res);
      });
      this.addUserGroupVisible = true;
    },
    handleChoosePartinUser()
    {
      if(this.addForm.PartInUserCode)
      {
        // console.log(this.addForm.PartInUserCode);
        // console.log(this.addForm.PartInUserName);
        // this.choosedPartinusers=[];
        var usercodeArr = this.addForm.PartInUserCode.split(',');
        var usernameArr = this.addForm.PartInUserName.split(',');
        var arr = new Array();
        for(var a=0;a<usercodeArr.length;a++)
        {
          if(usercodeArr[a])
          {
            arr.push({
              id:usercodeArr[a],
              name:usernameArr[a]
            })
          }
        }
        // console.log(arr)
        this.choosedPartinusers = arr;
      }
      this.addPartinUserVisible = true;
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBackPartin(data) {
        // console.log(data);
        var newdata = data.map(function (item) {
            return item["Id"];
        });
        var namenewdata = data.map(function (item) {
            return item["UserNameClaim"];
        });
        // console.log(newdata);

        var str = "";
        for (var i = 0; i < namenewdata.length; i++) {
            str += namenewdata[i]+ ",";
        }
        if (str.length > 0) {
            str = str.substr(0, str.length - 1);
        }

        var idstr = "";
        for (var i = 0; i < newdata.length; i++) {
            idstr += newdata[i]+ ",";
        }
        if (idstr.length > 0) {
            idstr = idstr.substr(0, idstr.length - 1);
        }
        this.partinName=str;
        this.addForm.PartInUserCode = idstr;
        this.addForm.PartInUserName = str;
        this.addPartinUserVisible=false;
    },
    handleChooseCCUser()
    {
      if(this.addForm.CCUserCode)
      {
        // console.log(this.addForm.CCUserCode);
        // console.log(this.addForm.CCUserName);
        // this.choosedusers=[];
        var usercodeArr = this.addForm.CCUserCode.split(',');
        var usernameArr = this.addForm.CCUserName.split(',');
        var arr = new Array();
        for(var a=0;a<usercodeArr.length;a++)
        {
          if(usercodeArr[a])
          {
            arr.push({
              id:usercodeArr[a],
              name:usernameArr[a]
            })
          }
        }
        this.choosedusers = arr;
      }
      this.addUserVisible=true;
    },
    handleChooseCCUser2()
    {
        this.addUserVisible2=true;
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBack2(data) {
        console.log(data);
        var newdata = data.map(function (item) {
            return item["Id"];
        });
        var namenewdata = data.map(function (item) {
            return item["UserNameClaim"];
        });
        console.log(newdata);

        var str = "";
        for (var i = 0; i < namenewdata.length; i++) {
            str += namenewdata[i]+ ",";
        }
        if (str.length > 0) {
            str = str.substr(0, str.length - 1);
        }

        var idstr = "";
        for (var i = 0; i < newdata.length; i++) {
            idstr += newdata[i]+ ",";
        }
        if (idstr.length > 0) {
            idstr = idstr.substr(0, idstr.length - 1);
        }
        this.ccusername2=str;
        this.editCCForm.CCUserCode = idstr;
        this.editCCForm.CCUserName = str;
        this.addUserVisible2=false;
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBack(data) {
        // console.log(data);
        var newdata = data.map(function (item) {
            return item["Id"];
        });
        var namenewdata = data.map(function (item) {
            return item["UserNameClaim"];
        });
        // console.log(newdata);

        var str = "";
        for (var i = 0; i < namenewdata.length; i++) {
            str += namenewdata[i]+ ",";
        }
        if (str.length > 0) {
            str = str.substr(0, str.length - 1);
        }

        var idstr = "";
        for (var i = 0; i < newdata.length; i++) {
            idstr += newdata[i]+ ",";
        }
        if (idstr.length > 0) {
            idstr = idstr.substr(0, idstr.length - 1);
        }
        this.ccusername=str;
        this.addForm.CCUserCode = idstr;
        this.addForm.CCUserName = str;
        this.addUserVisible=false;
    },
    callFunction(item) {
      this.filters = {
        name: item.search
      };
      this[item.Func].apply(this, item);
    },
    formatCreatedOn: function(row, colume) {
      return !row.LastUpdateTime || row.LastUpdateTime == ""
        ? ""
        : util.formatDate.format(new Date(row.LastUpdateTime), "yyyy-MM-dd");
    },
    formatMeetingTime: function(row, colume) {
      return !row.MeetingTime || row.MeetingTime == ""
        ? ""
        : util.formatDate.format(new Date(row.MeetingTime), "yyyy-MM-dd");
    },
    formatTime: function(row, colume) {
      // return !row.MeetingTime || row.MeetingTime == ""
      //   ? ""
      //   : util.formatDate.format(new Date(row.MeetingTime), "yyyy-MM-dd");
      return row.StartTime+'-'+row.EndTime;
    },
    formatTime2: function(time)
    {
      console.log(time)
      // var a= time.split(" ");
      // console.log(a)
      return '';
      // return time.split(' ')[1];
    },
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    selectCurrentTaskRow(val) {
      this.currentTaskRow = val;
    },
    handleCurrentChange (val) {
      this.page = val;
      this.queryMeetings();
    },
    handleMeetingTaskCurrentChange (val) {
      this.mtpage = val;
      this.getMeetingTasks();
    },
    queryJueyiTaskByMeetingId()
    {
      let self = this;
      var meetingid = self.currentRow.Id;
      // var user = JSON.parse(window.localStorage.user);
      QueryPageTasksByMeetingId({
        meetingId: meetingid,
        meetingname: this.filters.name,
        status: this.taskStatus,
        progressValue: this.progressValue,
        pageIndex: 1,
        pageSize: 10000,
      }).then(res => {
        // console.log(res);
        var arr = new Array();
        res.data.response.data.forEach(element => {
          if(element.IsHasCommonTasks)
          {
            element.IsHasChildren=true;
          }else{
            element.IsHasChildren=false;
          }
          arr.push(element);
        });
        this.meetingList = arr;
        // this.meetingList = res.data.response.data;
        // this.meetings = res.data.response.data;
        // this.total = res.data.response.pageCount;
        // this.totaldata = res.data.response.dataCount;
      });
    },
    getMeetings()
    {
      this.queryMeetings();
    },
    queryMeetings() {
      var user = JSON.parse(window.localStorage.user);
      QueryPageMeetingListByAboutUsercode({
        userCode: user.sub,
        meetingname: this.filters.name,
        pageIndex: this.page,
        pageSize: this.pageSize
      }).then(res => {
        // console.log(res);
        var arr=new Array();
        res.data.response.data.forEach(element => {
          var o=element;
          // console.log(element.StartTime.split(' ')[1]);
          o.StartTime = element.StartTime.split(' ')[1];
          o.EndTime = element.EndTime.split(' ')[1];
          // console.log(o);
          arr.push(o);
        });
        // console.log(arr)
        this.meetings = arr;
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
      });
    },
    //新增
    handleAdd() {
      this.title="新增会议信息";
      this.addFormVisible = true;
      this.addForm.Id = null;
      this.addForm.MeetingName = null;
      this.addForm.MeetingAddress = null;
      this.addForm.MeetingDescription = null;
      this.addForm.MeetingTime = null;
      this.addForm.StartTime = '';
      this.addForm.EndTime = '';
      // this.addForm.PublishCode = null;
      // this.addForm.PublishName = null;
      var user = JSON.parse(window.localStorage.user);
      this.addForm.PublishCode = user.sub;
      this.addForm.PublishName = user.name;
      this.addForm.ApprovalCode = null;
      this.addForm.ApprovalName = null;
      this.addForm.BeforeApproval = true;
      this.addForm.AfterApproval = false;
      this.addForm.CCUserCode = null;
      this.addForm.CCUserName = null;
      this.addForm.PartInUserCode = null;
      this.addForm.PartInUserName = null;
      this.addForm.Remark = null;
    },
    //新增提交
    addSubmit() {
      let para = Object.assign({}, this.addForm);
      var user = JSON.parse(window.localStorage.user);
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      para.CreatedDptId = window.localStorage.deptId;
      para.CreatedDpt = window.localStorage.deptName;
      para.StartTime = para.MeetingTime.split(' ')[0]+' '+para.StartTime;
      para.EndTime = para.MeetingTime.split(' ')[0]+' '+para.EndTime;
      if (
        this.addForm.MeetingName == null ||
        // this.addForm.ApprovalName == null ||
        this.addForm.PartInUserName == null
      ) {
        this.tipMessage();
        return;
      }else{
        if(
          this.addForm.MeetingName == '' ||
          // this.addForm.ApprovalName == '' ||
          this.addForm.PartInUserName == ''
          )
          {
            this.tipMessage();
            return;
          }
      }
      var exists=false;
      for(var a=0;a<this.meetings.length;a++)
      {
        if(this.meetings[a].MeetingName==this.addForm.MeetingName)
        {
          exists=true;
          break;
        }
      }
      if(exists && !this.addForm.Id)
      {
        this.$message({
          message: "会议名称不能重复，请检查！",
          type: "warning"
        });
        return;
      }
      
      this.Loadingstart();

      if(this.addForm.Id)
      {
        UpdateMeeting(para).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.$refs["addForm"].resetFields();
            this.addFormVisible = false;
            this.meetings=[];
            setTimeout(() => {
              this.queryMeetings();
            }, 100);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
            this.Loadignend();
          }
        });
      }else{
        AddMeeting(para).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.$refs["addForm"].resetFields();
            this.addFormVisible = false;
            this.meetings=[];
            setTimeout(() => {
              this.queryMeetings();
            }, 100);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
            this.Loadignend();
          }
        });
      }
    },
    handleEdit()
    {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "warning"
        });
        return;
      }
      var user = JSON.parse(window.localStorage.user);
      if(row.CreatedId!=user.sub)
      {
        this.$message({
          message: "当前用户非会议创建人，不允许编辑",
          type: "warning"
        });
        return;
      }
      this.addFormVisible = true;
      this.title="编辑会议信息";
      this.addForm.Id = row.Id;
      this.addForm.MeetingName = row.MeetingName;
      this.addForm.MeetingDescription = row.MeetingDescription;
      this.addForm.MeetingAddress = row.MeetingAddress;
      this.addForm.MeetingTime = row.MeetingTime;
      // console.log(row.StartTime.substr(0,5))
      // console.log(row.EndTime.substr(0,5))
      // this.addForm.StartTime = new Date(row.StartTime)
      this.addForm.StartTime = row.StartTime.substr(0,5);
      this.addForm.EndTime = row.EndTime.substr(0,5);
      this.addForm.PublishCode = row.PublishCode;
      this.addForm.PublishName = row.PublishName;
      this.addForm.ApprovalCode = row.ApprovalCode;
      this.addForm.ApprovalName = row.ApprovalName;
      this.addForm.BeforeApproval = row.BeforeApproval;
      this.addForm.AfterApproval = row.AfterApproval;
      this.addForm.CCUserCode = row.CCUserCode;
      this.addForm.CCUserName = row.CCUserName;
      this.addForm.PartInUserCode = row.PartInUserCode;
      this.addForm.PartInUserName = row.PartInUserName;
      this.addForm.Remark = row.Remark;
      // console.log(this.addForm)
    },
    //修改审批人
    handleEdit2() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error"
        });
        return;
      }
      this.addFormVisible = true;
      this.addForm.Id = row.Id;
      this.addForm.MeetingName = row.MeetingName;
      this.addForm.MeetingAddress = row.MeetingAddress;
      this.addForm.MeetingTime = row.MeetingTime;
      this.addForm.PublishCode = row.PublishCode;
      this.addForm.PublishName = row.PublishName;
      this.addForm.ApprovalCode = row.ApprovalCode;
      this.addForm.ApprovalName = row.ApprovalName;
      this.addForm.BeforeApproval = row.BeforeApproval;
      this.addForm.AfterApproval = row.AfterApproval;
      this.addForm.CCUserCode = row.CCUserCode;
      this.addForm.CCUserName = row.CCUserName;
      this.addForm.PartInUserCode = row.PartInUserCode;
      this.addForm.PartInUserName = row.PartInUserName;
      this.addForm.Remark = row.Remark;
    },
    //修改抄送人
    handleEditCCUser() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error"
        });
        return;
      }
      this.editCCFormVisible = true;
      this.editCCForm.Id = row.Id;
    },
    //编辑抄送人提交
    editCCSubmit() {
      let para = Object.assign({}, this.editCCForm);
      var user = JSON.parse(window.localStorage.user);
      para.LastUpdateUserCode = user.sub;
      para.LastUpdateUserName = user.name;
      this.$confirm("确认修改抄送人吗？", "提示", {}).then(() => {
        this.Loadingstart();
        UpdateMeetingUpholdCCUser(para).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.currentRow = null;
            this.editCCFormVisible = false;
            this.queryMeetings();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
          }
        });
      });
    },
    //编辑审批人提交
    editSubmit() {
      let para = Object.assign({}, this.editForm);
      var user = JSON.parse(window.localStorage.user);
      para.LastUpdateUserCode = user.sub;
      para.LastUpdateUserName = user.name;
      this.$confirm("确认修改审批人吗？", "提示", {}).then(() => {
        this.Loadingstart();
        UpdateMeetingUphold(para).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.currentRow = null;
            this.editFormVisible = false;
            this.queryMeetings();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
          }
        });
      });
    },
    handleCopy()
    {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要复制的一行数据！",
          type: "warning"
        });
        return;
      }
      this.addFormVisible = true;
      this.addForm.MeetingName = row.MeetingName;
      this.addForm.MeetingAddress = row.MeetingAddress;
      this.addForm.MeetingDescription = row.MeetingDescription;
      this.addForm.MeetingTime = row.MeetingTime;
      this.addForm.StartTime = row.StartTime;
      this.addForm.EndTime = row.EndTime;
      // this.addForm.PublishCode = row.PublishCode;
      // this.addForm.PublishName = row.PublishName;
      var user = JSON.parse(window.localStorage.user);
      this.addForm.PublishCode = user.sub;
      this.addForm.PublishName = user.name;
      this.addForm.ApprovalCode = row.ApprovalCode;
      this.addForm.ApprovalName = row.ApprovalName;
      this.addForm.BeforeApproval = row.BeforeApproval;
      this.addForm.AfterApproval = row.AfterApproval;
      this.addForm.CCUserCode = row.CCUserCode;
      this.addForm.CCUserName = row.CCUserName;
      this.addForm.PartInUserCode = row.PartInUserCode;
      this.addForm.PartInUserName = row.PartInUserName;
      this.addForm.Remark = row.Remark;
      // console.log(row);

    },
    //删除
    handleDelete() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "warning"
        });
        return;
      }
      var user = JSON.parse(window.localStorage.user);
      if(row.CreatedId!=user.sub)
      {
        this.$message({
          message: "当前用户非会议创建人，不允许删除",
          type: "warning"
        });
        return;
      }
      if(row.Published==1)
      {
        this.$message({
          message: "会议已发布，无法删除",
          type: "warning"
        });
        return;
      }
      this.$confirm("确定要删除选中的会议信息吗？", "提示", {}).then(() => {
        this.Loadingstart();
        let para = { meetingId: row.Id };
        DeleteMeetingById(para).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.currentRow = null;
            this.queryMeetings();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
          }
        });
      });
    },
    diaclose() {
      this.$refs.addForm.resetFields();
    },
    tipMessage() {
      this.$message({
        message: "表单填写未完成，请继续填写",
        type: "warning"
      });
    },
    //部门选择回调
    departcallFunction(departname) {
      this.catchdepart = departname;
    },
    //审核人回调
    assigncallFunction(newdata) {
      // console.log(newdata)
      if(newdata.length==0)
      {
        this.addForm.ApprovalCode = null;
        this.editForm.ApprovalCode = null;
        this.addForm.ApprovalName ='';
        this.editForm.ApprovalName = '';
        return;
      }
      this.addForm.ApprovalCode = newdata[0];
      this.editForm.ApprovalCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then(res => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.addForm.ApprovalName = res.data.data[0].name;
          this.editForm.ApprovalName = res.data.data[0].name;
        }
      });
    },
      tableRowClassName({row, rowIndex}){
            if (rowIndex % 2 !== 0) {
            return 'warning-row';
            }
            return '';    
       },
       //参会人回调
       partincallFunction(newdata){
        //  console.log(newdata)
          let canhuiusers = "";
          let canhuiusercodes = "";
         if(newdata.length == 1){
           this.addForm.PartInUserCode = newdata[0];
           let para = {userId: newdata[0]};
            GetUserPostList(para).then((res) => {
              // console.log(res)
              this.addForm.PartInUserName = res.data.data[0].name;
              // console.log(this.addForm);
            });
          }else{
            newdata.map(item => {
              // canhuiusercodes += item + ",";
              // this.addForm.PartInUserCode = canhuiusercodes.replace("null", "");
              let para = {userId: item};
              GetUserPostList(para).then((res) => {
                canhuiusers += res.data.data[0].name + ",";
                canhuiusercodes += res.data.data[0].id + ",";
                // console.log(canhuiusers);
                // console.log(canhuiusercodes);
                this.addForm.PartInUserCode =  canhuiusercodes.replace("null", "");
                this.addForm.PartInUserCode = this.addForm.PartInUserCode.substr(0, this.addForm.PartInUserCode.length - 1);
                this.addForm.PartInUserName =  canhuiusers.replace("null", "");
                this.addForm.PartInUserName = this.addForm.PartInUserName.substr(0, this.addForm.PartInUserName.length - 1);
              });
            })
          }
          // console.log(this.addForm.PartInUserCode)
          // console.log(this.addForm.PartInUserName)
       },
       //会议发布人回调
       usercallFunction(newdata){
          let fabuusers = "";
          let fabuusercodes = "";
         if(newdata.length == 1){
           this.addForm.PublishCode = newdata[0];
           let para = {userId: newdata[0]};
            GetUserPostList(para).then((res) => {
                    this.addForm.PublishName = res.data.data[0].name;
            });
          }else{
            newdata.map(item => {
              fabuusercodes += item + ",";
              this.addForm.PublishCode = fabuusercodes.replace("null", "");
              let para = {userId: item};
              GetUserPostList(para).then((res) => {
                      fabuusers += res.data.data[0].name + ",";
                      this.addForm.PublishName =  fabuusers.replace("null", "");
                      this.addForm.PublishName = this.addForm.PublishName.substr(0, this.addForm.PublishName.length - 1);
              });
            })
          }
       },
       Loadingstart(){
        　　this.loading = Loading.service({
        　　　　lock:true,
        　　　　text:'加载中...',
        　　　　background:'rgba(0,0,0,0.1)'
        　　})
        },
        Loadignend(){
        　　this.loading.close();
        }
  },
  mounted() {
    this.queryMeetings();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    var user = JSON.parse(window.localStorage.user);
    this.myusercode = user.sub;
    //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // this.getButtonList(routers);
    
    //第二种写法，封装到 permissionRouter.js 中
    let  buttons = window.localStorage.buttList? JSON.parse(window.localStorage.buttList) : [];
    this.buttonList = getButtonList(this.$route.path, routers,buttons);
  }
};
</script>
<style scoped>
/deep/.el-table__body tr.current-row > td {
  background: #fabe64 !important;
}
/deep/.el-table .warning-row {
    background: #F0F8FF;
  }
/deep/.el-dialog__header{
    padding:20px 20px 10px;
    /* background-color: #20B2AA; */
}
/deep/.el-icon-close:before{
    color: #FFFFFF;
}
</style>